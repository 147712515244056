<template>
  <v-bottom-sheet
    v-model="show"
    content-class="my-bottom-sheet address-bottom-sheet"
    scrollable
    inset
  >
    <v-sheet
      class="text-center full-height"
    >
      <div class="pa-3 text-right">
        <v-btn
          icon
          width="62"
          @click="closeOverlay"
          v-touch="{
            down: () => closeOverlay()
          }"
        >
          <v-icon>
            {{close_blue_btn}}
          </v-icon>
        </v-btn>
      </div>

      <div class="my-bottom-sheet-content full-height">
        <EditAddress
          :hideEditAddressBackBtn="true"
          @edit-address="editAddressAction"
        />
      </div>
    </v-sheet>

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-bottom-sheet>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import EditAddress from '@/components/SelectAddress/EditAddress';
  
  export default {
    name: 'EditAddressOverlay',
    components: {
      EditAddress
    },
    props: ["visible"],
    computed: {
      ...mapGetters([
        "userProfile",
        "isUserLoggedIn"
      ]),
      show: {
        get () {
          return this.visible
        },
        set (value) {
          if (!value) {
            this.$emit('close');
          }
        }
      }
    },
    data: () => ({
      showLoader: false,
      close_blue_btn: "$vuetify.icons.close_blue_btn",
      location_icon: "$vuetify.icons.location_icon",
      time_icon: "$vuetify.icons.time_icon",
      activeComponent: 'select-address-menu'
    }),
    methods: {
      ...mapActions([
        "getAddressList",
        "updateAddress"
      ]),
      closeOverlay() {
        let addressBottomSheet = document.getElementsByClassName('address-bottom-sheet')[0];
        addressBottomSheet.style.height = 'initial';
        this.$emit('close');
      },
      editAddressAction(addressObj) {
        this.showLoader = true;
        let email = this.userProfile.email;

        if (this.isUserLoggedIn) {
          let payload = {
            email: email,
            requestData: [addressObj]
          };

          this.updateAddress(payload).then(() => {
            this.getAddressList(email).then(() => {
              this.showLoader = false;
              this.$emit('close');
            });
          });
        }
      }
    }
  };
</script>

<style type="text/css">
  #my-app .new-address-title {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    color: #001254;
  }
</style>