<template>
  <v-container id="my-details">

    <CheckoutTopBar
      @go-back-action="goToMainFeed"
      @show-nav-drawer="showNavDrawer = true"
    />

    <div class="my-page-content">
      <v-row>
        <v-col cols="12">
          <div class="title-10 mb-1">
            Your Account
          </div>
          <div class="small-text">
            Your Contact Details
          </div>
        </v-col>
      </v-row>

      <v-list flat class="mt-2">
        <template v-for="(value, key) in contactDetails">
          <v-list-item
            class="pa-0"
          >
            <v-list-item-content class="text-left py-2">
              <v-list-item-title>
                <div class="title-12">
                  {{value}}
                </div>
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action
              class="my-2"
              @click="openContactDetailsModal"
            >
              <div class="small-text">
                Edit
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider
            v-if="key != 'email'"
            class="my-divider my-2"
          ></v-divider>
        </template>
      </v-list>

      <v-row class="mt-4 mb-1">
        <v-col cols="12">
          <div class="title-12">
            Your Locations
          </div>
        </v-col>
      </v-row>

      <v-list>
        <v-list-item
          v-for="(item, index) in addressList"
          class="my-list-item pa-0"
          :key="'address-'+index"
        >
          <v-list-item-icon class="my-5 mr-4">
            <v-icon size="20">
              {{location_icon}}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content class="py-5">
            <v-list-item-title
              two-line
              class="title-12 text-left"
            >
              {{item.name}}
            </v-list-item-title>

            <v-list-item-subtitle
              class="body-3"
            >
              {{item.street}} {{item.streetNumber}}, {{item.postCode}}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action
            class="small-text"
            @click="openEditAddressForm(item)"
          >
            Edit
          </v-list-item-action>
          <v-list-item-action
            class="small-text"
            @click.stop="deleteAddress(item.id)"
          >
            <v-icon size="14">{{trash_icon}}</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          class="px-0 py-2"
          @click="showAddNewAddressOverlay = true"
        >
          <v-list-item-icon class="pl-5 mr-5">
            <v-icon>
              {{add_btn}}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              class="title-13"
            >
              Add new address
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-row class="mt-4 mb-1">
        <v-col cols="12">
          <div class="title-12">
            Payment Details
          </div>
        </v-col>
      </v-row>

      <v-list>
        <v-list-item
          v-for="(item, index) in paymentMethods"
          class="my-list-item"
          :key="'pay-'+index"
        >
          <v-list-item-icon class="my-5 mr-4">
            <v-icon size="24">
              {{getItemIcon(item.icon)}}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              two-line
              class="title-12 text-left"
            >
              {{item.method}} {{item.details}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <AddNewAddressOverlay
      :visible="showAddNewAddressOverlay"
      @close="showAddNewAddressOverlay = false"
    />

    <EditAddressOverlay
      :visible="showEditAddressOverlay"
      @close="showEditAddressOverlay = false"
    />

    <CheckoutContactDetails
      :visible="showContactDetails"
      @close="showContactDetails = false"
      @edit-user="editUserAction"
    />
    
    <NavigationDrawer
      :visible="showNavDrawer"
      @close="showNavDrawer = false"
    />

    <v-overlay :value="showLoader" z-index=12>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import axios from 'axios';
  import CheckoutTopBar from '@/components/Navigation/CheckoutTopBar';
  import NavigationDrawer from '@/components/Navigation/NavigationDrawer';
  import AddNewAddressOverlay from '@/components/MyDetails/AddNewAddressOverlay';
  import EditAddressOverlay from '@/components/MyDetails/EditAddressOverlay';
  import CheckoutContactDetails from '@/components/Checkout/CheckoutContactDetails';

  export default {
    name: 'MyDetails',
    components: {
      CheckoutTopBar,
      NavigationDrawer,
      AddNewAddressOverlay,
      EditAddressOverlay,
      CheckoutContactDetails
    },
    computed: {
      ...mapGetters([
        "userProfile",
        "editProfile",
        "addressList",
        "paymentMethods"
      ]),
      contactDetails() {
        let result = {
          name: 'Name Surname',
          phoneNumber: 'Phone Number',
          email: 'Email'
        }
        if (Object.keys(this.userProfile).length != 0) {
          result.name = this.userProfile.firstName + ' ' + this.userProfile.lastName;
          result.phoneNumber = this.userProfile.phoneNumber;
          result.email = this.userProfile.email;
        }
        return result;
      },
    },
    data: () => ({
      publicPath: process.env.BASE_URL,
      showLoader: false,
      showNavDrawer: false,
      showAddNewAddressOverlay: false,
      showEditAddressOverlay: false,
      showContactDetails: false,
      location_icon: "$vuetify.icons.location_icon",
      add_btn: "$vuetify.icons.add_btn",
      apple_icon: "$vuetify.icons.apple_icon",
      credit_card_icon: "$vuetify.icons.credit_card_icon",
      cash_icon: "$vuetify.icons.cash_icon",
      pos_icon: "$vuetify.icons.pos_icon",
      trash_icon: "$vuetify.icons.trash_icon"
    }),
    methods: {
      ...mapActions([
        "setEditAddress",
        "setEditProfile",
        "editUserProfile",
        "getUserProfile",
        "getAddressList"
      ]),
      goToMainFeed() {
        this.$router.push({name: 'menu'});
      },
      getItemIcon(value) {
        return this[value];
      },
      openEditAddressForm(item) {
        let addressObj = JSON.parse(JSON.stringify(item));
        this.setEditAddress(addressObj).then(() => {
          this.showEditAddressOverlay = true;
        });
      },
      openContactDetailsModal() {
        let userObj = JSON.parse(JSON.stringify(this.userProfile));
        let data = {
          firstName: userObj.firstName,
          lastName: userObj.lastName,
          phoneNumber: userObj.phoneNumber,
          email: userObj.email
        }
        this.setEditProfile(data).then(() => {
          this.showContactDetails = true;
        })
      },
      editUserAction() {
        this.showContactDetails = false;
        this.showLoader = true;
        this.editUserProfile(this.editProfile).then(() => {
          this.getUserProfile().then(() => {
            this.showLoader = false;
          });
        });
      },
      deleteAddress(addressId) {
        this.showLoader = true;
        let email = this.userProfile.email;
        let url = `${process.env.VUE_APP_BASE_URL}/accounts/We%20Cook/customers/${email}/addresses/`+addressId;
        axios.delete(url).then(res => {
          this.getAddressList(email).then(() => {
            this.showLoader = false;
          });
        }).catch(er => {
          console.log(er);
        });
      }
    }
  };
</script>

<style type="text/css">
  #my-details {
    padding: 20px;
  }
</style>
